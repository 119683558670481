.App {
  text-align: center;
  color: #fff;
}
.App-title {
  font-size: 1.5em;
}

/*****/

.navbar .navbar-brand {
  font-size: 1em;
}

.nav-item a {
  font-weight: 600;
}

/*****/

.logoContainer {
  background-color: #000;
}

.logoContainer .container .row {
  padding-top: 2em;
  padding-bottom: 1em;
}

/*****/

.homeContainer, .textContainer, .contactContainer {
  background-color: #000;
}


@media (min-width: 900px) {
  .homeContainer section, .textContainer section, .contactContainer section {
    position:relative;
    height: 100%;
  }
  
  .homeContainer section .container {
    position:absolute;
    bottom: 35%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);  
  }
  
  .contactContainer section .container {
    position:absolute;
    bottom: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);  
  }

  .textContainer section .container {
    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    
  }
}


@media (max-width: 900px) {
  .homeContainer section, .textContainer section, .contactContainer section {
    padding-top: 200px;
    padding-bottom: 50px;
  }
}

.homeContainer section .container {  
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);  
}

.textContainer section .container .row:first-child {
  /* text-shadow: 2px 2px #000; */
  padding-top: 2em;
  padding-bottom: 1em;
  border-bottom: #000 dotted 0;
  
  background-color: rgb(0, 0, 0);
  /* RGBa with 0.6 opacity */
  background-color: rgba(0, 0, 0, 0.6);
}

.textContainer .container .row {
  /* text-shadow: 2px 2px #000; */
  padding-top: 2em;
  padding-bottom: 1em;
  border-bottom: #faac06 dotted 1px;
  
  background-color: rgb(0, 0, 0);
  /* RGBa with 0.6 opacity */
  background-color: rgba(0, 0, 0, 0.6);
}

.textContainer .container .row .col-md-8 {
  text-align: left;
}

.textContainer .container .row h3 {
  color: #faac06;
}

.textContainer .container .row svg {
  color: #faac06;
 /* background-color: #000; */
}

.colorRed {
  color: #ff0000;
}

/***********/



footer {
 background-color: #000;
 padding: 2% 2% 0 2%;
 font-size: 1em;
}

.mission {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 2em;
}

h1 {
  font-size: 2em;
  line-height: 1em;
}


/*
.collapsing {
  -webkit-transition: 5s;
  transition: 5s;
  display: 5s;
}

.collapse {
  -webkit-transition: 1s;
  transition: 1s;
  display: 1s;
}
*/

/* .collapse.show {
  -webkit-transition: 5s;
  transition: 5s;
  display: 5s;
} */

.cursorHand {
  cursor: pointer;
}

.partners .textContainer {
  padding-top: 5em;
}

.partners .textContainer .col-md-8 {
  padding: 2em 0;
}

.partners .textContainer .col-md-2 a {
  margin: 2em 0;
}

.distpart{
  margin: 2em 0 0 0;
}